import React from 'react';
import Masthead from '../components/Masthead';
import Footer from '../components/Footer';
import News from '../components/News';


// Hero Images


const About = () => {
    return (
        <>
            <Masthead />
            <div id='article-content' className='bg-white px-6 py-32 lg:px-8'>
                <div className='mx-auto max-w-3xl text-base leading-7 text-gray-700'>
                <h1 class='text-3xl font-bold tracking-tight text-emerald-900 sm:text-4xl pb-2'>About Us</h1>
                <p>
        RecruiterCRMPro is dedicated to revolutionizing the recruitment industry with cutting-edge technology and innovative solutions. We strive to provide the best possible tools and services to recruiters and staffing agencies, enabling them to streamline their processes and achieve greater success. Our mission is to empower recruiters to work more efficiently and effectively, transforming the way they connect with top talent.
    </p>

    <h3>Our Story</h3>
    <p>
        RecruiterCRMPro was born out of a passion for improving the recruitment industry. Recognizing the challenges faced by recruiters in managing vast amounts of data, coordinating with clients, and engaging with candidates, we set out to create a comprehensive CRM solution tailored specifically for recruitment professionals. Our goal is to simplify the recruitment process and help you achieve your hiring goals with greater efficiency and effectiveness.
    </p>

    <h3>Our Software</h3>
    <p>
        Our platform offers a wide array of features designed to streamline and optimize your recruitment efforts. Whether it's managing your candidate pipeline, engaging with clients, or tracking performance analytics, RecruiterCRMPro provides the tools you need to succeed.
    </p>

    <h3>Our Team</h3>
    <p>
        At RecruiterCRMPro, our team is composed of dedicated professionals with extensive experience in recruitment, technology, and customer service. We are passionate about innovation and committed to delivering a product that meets the evolving needs of modern recruiters. Our team works tirelessly to ensure that our platform empowers recruiters to achieve their goals.
    </p>

    <h3>Our Culture</h3>
    <p>
        Our culture is built on collaboration, innovation, and a commitment to excellence. We believe in creating a supportive and inclusive environment where every team member can thrive. Our core values include integrity, customer focus, and continuous improvement. We are proud to foster a culture that encourages creativity and values every individual's contributions.
    </p>
</div>
            </div>
            <Footer />
        </>
    );
};

export default About;