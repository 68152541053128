import React from 'react';

const Skeleton = () => {
    return (
        <div className="bg-white px-6 pb-20 pt-16 lg:px-8 lg:pb-28 lg:pt-24">
            <div className="relative mx-auto max-w-lg divide-y-2 divide-gray-200 lg:max-w-7xl">
                <div>
                    <div className="h-8 bg-gray-200 rounded w-1/3 animate-pulse"></div>
                    <div className="mt-3 h-6 bg-gray-200 rounded w-1/2 animate-pulse"></div>
                </div>
                <div className="mt-12 grid gap-16 pt-12 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-12">
                    {[...Array(6)].map((_, index) => (
                        <div key={index}>
                            <div>
                                <div className="block">
                                    <div className="rounded-lg bg-emerald-200 p-2 shadow-md animate-pulse">
                                        <div className="w-full h-48 bg-gray-200 rounded-lg"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4 block">
                                <div className="h-6 bg-gray-200 rounded w-3/4 animate-pulse"></div>
                                <div className="mt-3 h-4 bg-gray-200 rounded w-full animate-pulse"></div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>

    );
};

export default Skeleton;