function Logo() {
    return (
<>
<img
            src="/img/rcrmprologo.png"
            alt="RecruiterCRMPro - Image of a"
            className="w-72 md:w-96 lg:w-96 h-auto"

          />
</>
    )
}

export default Logo;