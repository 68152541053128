import { CloudArrowUpIcon, LockClosedIcon, ServerIcon, ChartBarIcon, HandRaisedIcon, UserGroupIcon, CheckBadgeIcon } from '@heroicons/react/20/solid'
import SwooshDivider from './SwooshDivider'

const features = [
    {
        name: 'Capture New Leads',
        description: 'Capture leads using our landing pages, surveys, forms, calendars, inbound phone system & more!',
        icon: ChartBarIcon,
    },
    {
        name: 'Nurture Leads Into Clients and Candidates!',
        description: 'Automatically message leads via voicemail, forced calls, SMS, emails, FB Messenger, LinkedIn & more!',
        icon: HandRaisedIcon,
    },
    {
        name: 'Fully Automated Appointment Setting',
        description: 'Dreaming of the perfect audience? RecruiterCRMPro makes it a reality. We target with the precision of a cupid, ensuring your messages hit the bullseye every time. ',
        icon: UserGroupIcon,
    },
    {
      name: 'Let\'\s Get it Done. Close The Deal.',
      description: 'Use our built in tools to collect payments, schedule appointments, and track analytics!',
      icon: CheckBadgeIcon, 
    }
]


export default function Hero() {
  return (
    <div className="overflow-hidden bg-emerald-900 py-24 sm:py-32 relative">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 ">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-emerald-400">Introducing your Digital Recruiting Marketing Super Power</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Streamline Your Recruiting Process with  RecruiterCRMPro</p>
              <p className="mt-6 text-lg leading-8 text-gray-300">
                Our Philosophy is simple. Help you scale your business without throwing money away on hiring a whole team of marketers, appointment setters.  Our software does the heavy lifting for you.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-300 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-white">
                      <feature.icon className="absolute left-1 top-1 h-6 w-6 text-emerald-500" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
                <a href="#request" className='font-semibold text-white text-lg bg-emerald-700 hover:bg-emerald-600 px-4 py-2 rounded-lg lg:ml-8 mt-4 w-full md:w-auto inline-block'>
                    Get Started
                </a>
            </div>
          </div>
          <img
            src="./img/riseup.png"
            alt="RecruiterCRMPro - Image of a"
            className="w-[48rem] max-w-none sm:w-[57rem] md:-ml-4 lg:-ml-0"
            
            width={2432}
            height={1442}
          />
        </div>
      </div>
      <SwooshDivider />
    </div>
  )
}
