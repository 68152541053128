import React from 'react';
import Masthead from './Masthead';
import Footer from './Footer';
import Hero from './Hero';
import Section from './Section';
import Reviews from './Reviews';
import Stats from './Stats';
import Pricing from './Pricing';
import Compare from './Compare';
import Testimonial from './Testimonial';

// Hero Images



const LeadCapture = [
    {
        name: 'Create Full Websites, Funnels and Landing Pages',
        description: 'Our intuitive platform allows you to create full featured websites with custom menus. Create high-performing and captivating landing pages all in one place!',
    },
    {
        name: 'Drag and Drop Page Builder',
        description: 'Built right in is the ability to capture leads through Surveys and capture forms. You can integrate directly with our page builder or embed them on your own sites.',
    },
    {
        name: 'Online Appointment Setting',
        description: 'The major step for many businesses is to capture appointments and request appointments. We\'ve built our own calendar application within RecruiterCRMPro so you can capture the appointment all in one straightforward flow.',
    }
]
const LeadNuture = [
    {
        name: 'Easily Customize Your Follow-Up Campaigns',
        description: 'Our Multi-channel follow up campaigns allow you to automate engaging follow ups and capture engaged responses from your leads.',
    },
    {
        name: 'Create Multi-Channel Campaigns',
        description: 'Our Software allows you to connect with your leads through Phone Connect, Voicemail Drops, SMS/MMS, Emails, and even Facebook Messenger.',
    },
    {
        name: 'Create Two-Way Conversations On Any Device on Auto-Pilot',
        description: 'Our full featured mobile app allows you to communicate with your leads on all devices.',
    }
]
const AppointmentSetting = [
    {
        name: 'Automated Nurture Conversations',
        description: 'Create text conversations with the goal of placing booked appointments on calendars WITHOUT any human interaction.',
    },
    {
        name: 'Fully Customizable Messaging',
        description: 'Use our campaign builder to customize the messaging.',
    },
    {
        name: 'AI Is Built In.',
        description: 'Our Platform allows you to leverage AI (Artificial Intelligence) and Machine Learning to manage the conversation.',
    }
]
const ClosingPoints = [
    {
        name: 'Manage Your Workflow and Pipeline',
        description: 'With our built-in Pipeline Management feature, you can keep track of where the leads are and what stage they are in the sales funnel.',
    },
    {
        name: 'Collect Customer Payments',
        description: 'We integrate directly with Stripe so you can collect payments on websites, funnels, and even when someone books an appointment.',
    },
    {
        name: 'All your Analytics in One Place',
        description: 'Our dashboard keeps an overview of where the leads are, and how much money has been generated on each phase.',
    }
]
const Home = () => {
    return (
        <>
            <Masthead />
            <Hero/>

            {/* { title, para, img, subheading, herotext, id, reverse, bullets, person } */}
            <Section title={'Capture New Leads for Client and Candidates'} img={'./img/hero.webp'} customImage={'./img/rcmprohero2.png'} reverse={true} subheading={'Build Long Lasting Relationships'} herotext={'I simply cannot imagine doing this without RecruiterCRMPro now. it has completely changed the our engagement strategies for the better. '} person={"Blake B. Owner & CEO - AdvancedTech LLC "} para={'RecruitCRMPro is a Full Suite Platform for Recruiting Focused Marketers. Included in the Platform is a full featured Page Builder to capture leads.'} bullets={LeadCapture} />

            <Stats/>
            <Section title={'Nurture Leads Into Long Time Clients, Candidates and Friends.'} customImage={'./img/nurture.png'} img={'./img/hero2.webp'} reverse={false} subheading={'Fostering Great Communication'} herotext={'herotext'} para={'The backbone of RecruiterCRMPro is what you are able to accomplish after you capture the lead.'} bullets={LeadNuture} />

            <Section title={'Fully Automated Appointment Setting'} customImage={'./img/iphone.png'} reverse={true} subheading={'Get Face-to-Face with ease.'} herotext={'herotext'} para={'Automatically book leads and prospects to your calendar without lifting a finger.'} bullets={AppointmentSetting} />
            <Section title={'Close More Deals'} img={'./img/hero3.webp'} customImage={'./img/closing.png'} reverse={false} subheading={'Marc Cuban would be impressed.'} herotext={'Everything is seemless, Once they reach out by any channel, we have a person record and we can engage with them effortlessly.  A true masterclass in OmniChannel Recruiting'} person={'Frank C. - SupaSellas'} para={'Gain Visibility in to the sources that are producing leads and conversions to clients and candidates alike. '} bullets={ClosingPoints} />
            <Testimonial/>
            <Reviews />
            <Compare />
            <Pricing />
            <Footer />
        </>
    );
};

export default Home;