import React from 'react';
import Logo from './Logo';
import { CheckBadgeIcon } from '@heroicons/react/20/solid';

const data = [
    { feature: "Document Signing", replaces: "PandaDoc, DocuSign", otherTools: "$47/m", highLevel: <CheckBadgeIcon className="h-8 w-8 text-emerald-500 text-center inline" /> },
    { feature: "CRM & Pipeline Management", replaces: "HubSpot, Pipedrive", otherTools: "$99/m", highLevel: <CheckBadgeIcon className="h-8 w-8 text-emerald-500 text-center inline" /> },
    { feature: "Unlimited Sales Funnels", replaces: "ClickFunnels", otherTools: "$297/m", highLevel: <CheckBadgeIcon className="h-8 w-8 text-emerald-500 text-center inline" /> },
    { feature: "Website Builder", replaces: "WordPress, Wix, Squarespace", otherTools: "$29/m", highLevel: <CheckBadgeIcon className="h-8 w-8 text-emerald-500 text-center inline" /> },
    { feature: "Surveys & Forms", replaces: "SurveyGizmo, Typeform, Wufoo", otherTools: "$49/m", highLevel: <CheckBadgeIcon className="h-8 w-8 text-emerald-500 text-center inline" /> },
    { feature: "Email Marketing", replaces: "HubSpot, Mailchimp, ActiveCampaign", otherTools: "$99/m", highLevel: <CheckBadgeIcon className="h-8 w-8 text-emerald-500 text-center inline" /> },
    { feature: "2-Way SMS Marketing", replaces: "Twilio, SimpleTexting, EZ Texting", otherTools: "$99/m", highLevel: <CheckBadgeIcon className="h-8 w-8 text-emerald-500 text-center inline" /> },
    { feature: "Booking & Appointments", replaces: "Calendly, ScheduleOnce, Acuity Scheduling", otherTools: "$29/m", highLevel: <CheckBadgeIcon className="h-8 w-8 text-emerald-500 text-center inline" /> },
    { feature: "Workflow Automations", replaces: "HubSpot, Keap (Infusionsoft)", otherTools: "$169/m", highLevel: <CheckBadgeIcon className="h-8 w-8 text-emerald-500 text-center inline" /> },
    { feature: "Courses/Products", replaces: "Kajabi, Teachable", otherTools: "$99/m", highLevel: <CheckBadgeIcon className="h-8 w-8 text-emerald-500 text-center inline" /> },
    { feature: "Call Tracking", replaces: "CallRail, CallTrackingMetrics", otherTools: "$49/m", highLevel: <CheckBadgeIcon className="h-8 w-8 text-emerald-500 text-center inline" /> },
    { feature: "Reputation Management", replaces: "BirdEye, Podium", otherTools: "$159/m", highLevel: <CheckBadgeIcon className="h-8 w-8 text-emerald-500 text-center inline" /> },
    { feature: "Tracking & Analytics", replaces: "Google Analytics, Mixpanel", otherTools: "$299/m", highLevel: <CheckBadgeIcon className="h-8 w-8 text-emerald-500 text-center inline" /> },
    //   { feature: "Communities", replaces: "Skool, Mighty Networks, Circle", otherTools: "$89/m", highLevel: <CheckBadgeIcon className="h-8 w-8 text-emerald-500 text-center inline" /> },


];

const Compare = () => {
    return (
        <div className=" bg-white w-full p-0 sm:p-12 md:p-24 lg:p-12 overflow-x-hidden">
            <img
                src={"./img/statshero.png"}
                alt={"RecruiterCRMPro - How we compare - Image of People with rocketing sales. "}
                className="w-3/4 md:w-1/2 mx-auto h-auto"

            />
            <div className="text-center pb-8">
                <h3 className="text-4xl font-bold tracking-tight text-emerald-900 sm:text-5xl ">How we stack up against the competition. </h3>
                <p className='mt-4 text-lg leading-8 text-emerald-600'>
                    We are not just a CRM. We are a complete business solution for individuals and small to large agencies alike.
                </p>
            </div>
            <div className='overflow-x-auto'>
                <table className=" md:w-11/12 lg:w-11/12 mx-auto min-w-full max-w-full overflow-x-scroll rounded-lg shadow-md shadow-emerald-900/20 border bg-white">
                    <thead>
                        <tr className="">
                            <th className="py-4 px-2 bg-gray-50 border-b text-sm font-bold text-emerald-700">Our Features</th>
                            <th className="py-4 px-2 bg-gray-50 border-b text-sm font-bold text-emerald-700">Replaces</th>
                            <th className="py-4 px-2 bg-gray-50 border-b text-sm font-bold text-emerald-700">Other Tools</th>
                            <th className="py-4 px-2 bg-gray-50 border-b text-sm font-bold text-emerald-700 max-w-48"><Logo /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => (
                            <tr key={index} className="">
                                <td className="py-2 px-2 border-b border-r text-xs text-gray-500 bg-emerald-50 font-bold text-right">{item.feature}</td>
                                <td className="py-2 px-2 border-b text-xs text-gray-500">{item.replaces}</td>
                                <td className="py-2 px-2 border-b text-xs text-gray-500">{item.otherTools}</td>
                                <td className="py-2 px-2 border-b text-xs text-gray-500 text-center">{item.highLevel}</td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td></td>
                            <td>
                                <p className='inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10'>$7,111/m</p>
                            </td>
                            <td className='py-3 text-center'>
                                <span className='text-gray-400 text-sm block'>Startiog at only</span>
                                <p className='inline-flex items-center  px-2 py-1 text-2xl font-bold text-emerald-500 ring-1 ring-inset ring-emerald-600/10'> $349.95/m</p>
                            </td>
                        </tr>
                    </tfoot>
                </table>

            </div>

        </div>
    );
};

export default Compare;
