import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'

const frequencies = [
  { value: 'monthly', label: 'Monthly', priceSuffix: '' },
  // { value: 'annually', label: 'Annually', priceSuffix: '/year' },
  { value: 'single', label: 'One-Time', priceSuffix: '' },
]
const tiers = [

  {
    name: 'The Small Fry - Individual',
    id: 'tier-freelancer',
    href: 'https://buy.stripe.com/aEU8yY9sfc3J2dy7ss',
    price: { monthly: '$349.95', annually: '$12,995.00', single: '$500.00' },
    description: 'Perfect for smaller one person agencies. You get all the great features of the platform. ',
    features: ['1 Seat License', 'Great Customer Support ', 'Analytics', 'AI Automations', 'Contact Management','Website Builder', 'Landing Page Builder', 'Ad Creative Design', 'Ad Copywriting', 'Ad Management', 'Ad Reporting'],
    featured: false,
    cta: 'Subscribe',
    terms: '* Pricing does not include ad spend. Ad spend is billed directly to the customer by the ad platform.'
  },
  // {
  //   name: 'The Basket Fry - Small Agency',
  //   id: 'tier-business',
  //   href: 'https://buy.stripe.com/bEU8yY2gJc3J6W8aEH',
  //   price: { monthly: '$995.95', annually: '$5895.00', single: '$500.00' },
  //   description: 'Custom Landing Page Design & Development',
  //   features: ['Responsive Design', 'SEO-Friendly', 'Lead Capture Form Integration', '2 Rounds of Revisions'],
  //   featured: false,
  //   cta: 'Subscribe',
  //   terms: '* Hosting and domain fees not included.'
  // },
  {
    name: 'Enterprise',
    id: 'tier-enterprise',
    href: '#',
    price: 'Custom',
    description: 'Dedicated Resources and Support for your business and more! Contact us to get started.',
    features: [
      'Unlimited Business Entities',
      'Unlimited Budget Ad Spend',
      'Advanced analytics',
      '1-hour, dedicated support response time',
      'Marketing automations and custom reporting tools',
      'CRM integration and support',
      'Ad Creative Design and Copywriting',
      'Custom Landing Page Design and Development',
      `Data Analysis and Reporting`,
    ],
    featured: true,
    cta: 'Contact Us',
    terms: '* Pricing does not include ad spend. Ad spend is billed directly to the customer by the ad platform.'
  },
  

]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Pricing() {
  const [frequency, setFrequency] = useState(frequencies[0])

  return (
    <div id="pricing" className="bg-emerald-100/10 py-24 sm:py-32 border-t relative">
      <img
        src="/img/astroplans.png"
        alt="RecruiterCRMPro - Pricing Plans"
        className="absolute -top-24 md:-top-24 -left-9 w-32 md:w-48 lg:w-96 xl:w-96"
        
      />
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-base font-semibold leading-7 text-emerald-600">Pricing</h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-emerald-900 sm:text-5xl">
          Plans for Every Recruiter. 
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
            Choose the plan that best fits your needs and budget. You can change this later to suite your needs. 
        </p>
        {/* Pricing Switch, not needed right now.  */}
        {/* <div className="mt-16 flex justify-center">
          <RadioGroup
            value={frequency}
            onChange={setFrequency}
            className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
          >
            <RadioGroup.Label className="sr-only">Payment frequency</RadioGroup.Label>
            {frequencies.map((option) => (
              <RadioGroup.Option
                key={option.value}
                value={option}
                className={({ checked }) =>
                  classNames(
                    checked ? 'bg-indigo-600 text-white' : 'text-gray-500',
                    'cursor-pointer rounded-full px-2.5 py-1'
                  )
                }
              >
                <span>{option.label}</span>
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div> */}
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {tiers.map((tier) => (
            <div
              key={tier.id}
              className={classNames(
                tier.featured ? 'bg-emerald-900 ring-gray-900 shadow-lg' : 'ring-gray-200 bg-white shadow-lg',
                'rounded-3xl p-8 ring-1 xl:p-10'
              )}
            >
              <h3
                id={tier.id}
                className={classNames(
                  tier.featured ? 'text-white' : 'text-gray-900',
                  'text-lg font-semibold leading-8'
                )}
              >
                {tier.name}
              </h3>
              <p className={classNames(tier.featured ? 'text-gray-300' : 'text-gray-600', 'mt-4 text-sm leading-6')}>
                {tier.description}
              </p>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span
                  className={classNames(
                    tier.featured ? 'text-white' : 'text-gray-900',
                    'text-4xl font-bold tracking-tight'
                  )}
                >
                  {typeof tier.price === 'string' ? tier.price : tier.price[frequency.value]}
                </span>
                {typeof tier.price !== 'string' ? (
                  <span
                    className={classNames(
                      tier.featured ? 'text-gray-300' : 'text-gray-600',
                      'text-sm font-semibold leading-6'
                    )}
                  >
                    {frequency.priceSuffix}
                  </span>
                ) : null}
              </p>
               
              <a
                href={tier.href}
                aria-describedby={tier.id}
                className={classNames(
                  tier.featured
                    ? 'bg-emerald-100/10 text-white hover:bg-emerald-100/30 focus-visible:outline-white'
                    : 'bg-emerald-600 text-white shadow-sm hover:bg-emerald-500 focus-visible:outline-emerald-600',
                  'mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'
                )}
              >
                {tier.cta}
              </a>
              {/* Terms */}
              <p className="mt-2 text-xs leading-6 text-gray-400 font-thin">{tier.terms}</p>
              <ul
                role="list"
                className={classNames(
                  tier.featured ? 'text-gray-300' : 'text-gray-600',
                  'mt-8 space-y-3 text-sm leading-6 xl:mt-10'
                )}
              >
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon
                      className={classNames(tier.featured ? 'text-white' : 'text-emerald-600', 'h-6 w-6 flex-none')}
                      aria-hidden="true"
                    />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
