const stats = [
    { id: 1, name: 'Automated Client Conversations', value: '15,000,000+' }, // Reflects the number of successful job matches facilitated by the software's automated algorithms.
    { id: 2, name: 'Enhancement in Client Efficiency', value: '100% Increase' }, // Shows the average improvement in recruitment process efficiency among clients using your software.
    { id: 3, name: 'Reduction in Time-to-First-Contact', value: '99%' }, // Indicates how much your software reduces the average time-to-hire, demonstrating speed and efficiency.
    { id: 4, name: 'User Satisfaction Rate', value: '97%' }, // Measures the satisfaction rate among users of your software, indicating high approval and functionality.
    { id: 5, name: 'Active Users', value: '5,000+' }, // Shows the number of active users, emphasizing the software's popularity and reliability.
    { id: 6, name: 'Year-on-Year Retention', value: '200%' }, // Depicts the annual growth rate of your software's user base, showcasing rapid expansion and market adoption.
    { id: 7, name: 'Client Retention Rate Avg Increase', value: '42%' }, // Maintains the high percentage of repeat clients, underscoring client satisfaction and loyalty.
    { id: 8, name: 'Avg Productivity Gains', value: '1000%' }, // Maintains the high percentage of repeat clients, underscoring client satisfaction and loyalty.
]

  

export default function Stats() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <div className="text-center">
            <h2 className="text-3xl font-bold tracking-tight text-emerald-900 sm:text-4xl">
            Our Numbers Speak to why Recruiters Absolutely Love our Platform. 
            </h2>
            <p className="mt-4 text-lg leading-8 text-emerald-600">
            Discover the key metrics that showcase our effectiveness and commitment in the IT staffing industry. These statistics reflect our dedication to excellence and client satisfaction, ensuring top-tier service and successful placements.
            </p>
          </div>
          <dl className="mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
            {stats.map((stat) => (
              <div key={stat.id} className="flex flex-col bg-gray-400/5 p-8">
                <dt className="text-sm font-semibold leading-6 text-emerald-600">{stat.name}</dt>
                <dd className="order-first text-3xl font-semibold tracking-tight text-emerald-900">{stat.value}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
