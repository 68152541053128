import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Masthead from '../components/Masthead';
import Footer from '../components/Footer';
import News from '../components/News';
import Pricing from '../components/Pricing';

const GRAPHQL_ENDPOINT = 'https://us-east-1-shared-usea1-02.cdn.hygraph.com/content/clz8r1dl7005w07w4og3y38yy/master';
const ACCESS_TOKEN = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE3MjIzNzU3OTQsImF1ZCI6WyJodHRwczovL2FwaS11cy1lYXN0LTEtc2hhcmVkLXVzZWExLTAyLmh5Z3JhcGguY29tL3YyL2NsejhyMWRsNzAwNXcwN3c0b2czeTM4eXkvbWFzdGVyIiwibWFuYWdlbWVudC1uZXh0LmdyYXBoY21zLmNvbSJdLCJpc3MiOiJodHRwczovL21hbmFnZW1lbnQtdXMtZWFzdC0xLXNoYXJlZC11c2VhMS0wMi5oeWdyYXBoLmNvbS8iLCJzdWIiOiI3OWMwMjdhMS02YzdiLTQ4M2EtOWYzNi04YTZjNWY0ZjJiOTQiLCJqdGkiOiJjbHo4eTJzZGIwNDBiMDdsZGNudXdnd3ZqIn0.HFyvNOdOj8yZSOISCNGQ6O6htp9so8RacXDDm2c-NcfnOuEbIlT2EFt-874KPTUoTFRIIItF9gk-6nNLZjESV9El3fanZ0dPSPMGHxxRKdATycrh4dJbCkE5gfWZ0uB-8r4X-TVYairTNBTN-xVOPwPZJuEhrhO7dBz5P9z7fnry6AW4w9hNZtPD4a05TZ0YPIkp1mp1Ka0thsbyWfRILYYDJ-4Pi_DJ4pBExmdrN5yDN2wZhmG7e5FbjyrNZxFYtjMH33aN2zs-HVA4npR59cCi-gw9tHJqbJNoY76jMytDsKcw1-9ATnHkJ_aqQEy8ILD5_F6YGJ4skomyyJm0J-cMdTuTZAmAIDPpWI581dM5-fAJvHtakEbdO-UBGqsJBs55SFtPQLdqMt6aGs8qFkqRnJrs1pD8PCW2U7udd60UN6tWbe5K-qV-cAfX81Aa_oj7fODaTuMIhzuQ5C2BrMRNfq0qEE1VuXedLOQieHsQue6GRWIOq-Xt6ISDei6rtbMkadNjHF9sbP0lr9xi-wBgzk1YuC8UfQC8rcIki4yuwu8lcf1COv_3a7NjRuBx3nELLIgE--pe6ezTIlzI8u0fsVQYyTPi04i4duTzzKLp503kz-tTAB-IMnHie_EEqvvrnmgxFie4My66zN3s76DgGsqIRJvKxlB3OyOJQIM'; // Replace with your actual access token

const fetchArticle = async (slug) => {
    const query = `
    query GetArticle($slug: String!) {
      blogPost(where: { slug: $slug }) {
        title
        subtitle
        content {
          html
        }
        image {
          url
        }
      }
    }
  `;

    const variables = { slug };

    const response = await fetch(GRAPHQL_ENDPOINT, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${ACCESS_TOKEN}`
        },
        body: JSON.stringify({ query, variables })
    });

    const data = await response.json();
    if (data.errors) {
        throw new Error(data.errors.map(error => error.message).join(', '));
    }
    return data.data.blogPost;
};

const Article = () => {
    const { slug } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [article, setArticle] = useState(null);

    useEffect(() => {
        fetchArticle(slug)
            .then((article) => {
                setArticle(article);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    }, [slug]);

      if (loading) return <p>Loading...</p>;
    //   if (error) return <p>Error: {error.message}</p>;

    return (
        <>
            <Masthead />
            
            {article && !error ?
                <>
                    <meta name="description" content={article.subtitle} />
                    <meta property="og:title" content={article.title} />
                    {/* <meta property="og:description" content={article.subtitle} /> */}
                    <meta property="og:image" content={article.image.url} />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:type" content="article" />

                    <div className='bg-white px-6 py-32 lg:px-8'>
                        <div className='mx-auto max-w-3xl text-base leading-7 text-gray-700'>
                            <p className='text-base font-semibold leading-7 text-emerald-600'>{article.subtitle}</p>
                            <h1 className='mt-2 pb-5 text-3xl font-bold tracking-tight text-emerald-900 sm:text-4xl'>{article.title}</h1>
                            <div id="article-content" dangerouslySetInnerHTML={{ __html: article.content.html }} />
                        </div>
                    </div>
                </>
                : 
                <div className='bg-white py-24'>
                    <h1 className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center'>Article Not Found ... </h1>
                    <p className='text-emerald-700 text-center'>Check Out some of our other links below.</p>
                </div>
            }

            <News />
            <Pricing />
            <Footer />
        </>
    );
};

export default Article;
