import React from 'react';
import Masthead from '../components/Masthead';
import Footer from '../components/Footer';
import News from '../components/News';


// Hero Images


const LatestNews = () => {
    return (
        <>
            <Masthead />
            <News />
            <Footer />
        </>
    );
};

export default LatestNews;