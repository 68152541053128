import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid'
import Logo from './Logo'
{/* <a href='#services' className='px-6 py-2 text-emerald-500 text-md font-semibold hover:bg-emerald-500 rounded-full bg-opacity-10 hover:bg-opacity-20 transition-all'>Services</a>
<a href='#work' className='px-6 py-2 text-emerald-500 text-md font-semibold hover:bg-emerald-500 rounded-full bg-opacity-10 hover:bg-opacity-20 transition-all'>Work with us</a>
<a href='#support' className='px-6 py-2 text-emerald-500 text-md font-semibold hover:bg-emerald-500 rounded-full bg-opacity-10 hover:bg-opacity-20 transition-all'>Support</a>
<a href='#company' className='px-6 py-2 text-emerald-500 text-md font-semibold hover:bg-emerald-500 rounded-full bg-opacity-10 hover:bg-opacity-20 transition-all'>Company</a>
<a href='#request' className=' px-6 py-2 rounded-full bg-emerald-800 text-white text-md font-semibold shadow-lg'>Request Information</a> */}
const navigation = [
  { name: 'About Us', href: '/about' },
  { name: 'Latest News', href: '/news' },
// //   { name: 'Pricing', href: '#pricing' },
//   { name: 'Features', href: '#work' },
//   { name: 'Pricing', href: '#company' },
//   { name: 'Resources', href: '#request' },
]

export default function Masthead() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <header className="bg-emerald-50 backdrop-blur-sm bg-opacity-95 sticky top-0 z-10 shadow-emerald-800 drop-shadow">
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
        <a href="/" className="-m-1.5 p-1.5">
        <Logo />
        </a>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12 items-center">
          {navigation.map((item) => (
            <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-emerald-700">
              {item.name}
            </a>
          ))}
          <a href="#pricing" className="text-sm font-semibold leading-6 text-white bg-emerald-600 px-6 py-2 rounded-full">
            Subscribe Now <span aria-hidden="true">&rarr;</span>
          </a>
          <a href="https://app.recruitercrmpro.com" className="text-sm font-semibold leading-6 text-emerald-900 bg-emerald-300 px-6 py-2 rounded-full">
            Login 
          </a>
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Recruiter CRM Pro</span>
              <Logo />
            </a>
            
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <div className="py-6">
                <a
                  href="#"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white hover:bg-gray-50"
                >
                  Request Info
                </a>
                
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}
