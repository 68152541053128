import { StarIcon } from '@heroicons/react/20/solid'

const reviews = [
  {
    id: 1,
    title: "Exceptional Candidate Sourcing",
    rating: 5,
    content: `
      <p>RecruiterCRMPro has completely revolutionized our candidate sourcing process. The tools and analytics provided have significantly enhanced our ability to find top talent efficiently. Highly recommended for any recruitment team looking to improve their hiring process.</p>
    `,
    author: 'Risako M',
    date: 'April 3, 2024',
    datetime: '2023-04-03',
  },
  {
    id: 2,
    title: "Streamlined Recruitment Workflows",
    rating: 5,
    content: `
      <p>The efficiency of our recruitment workflows has greatly improved thanks to RecruiterCRMPro. The intuitive design and robust features like automated follow-ups and integrated candidate tracking have saved us countless hours.</p>
    `,
    author: 'James L',
    date: 'June 9, 2024',
    datetime: '2023-06-09',
  },
  {
    id: 3,
    title: "Enhanced Client Interactions",
    rating: 4,
    content: `
      <p>RecruiterCRMPro has been instrumental in enhancing our interactions with clients. The CRM tools help us keep track of all communications and ensure nothing slips through the cracks, making us much more efficient.</p>
    `,
    author: 'Sophia B',
    date: 'July 15, 2023',
    datetime: '2023-07-15',
  },
  {
    id: 4,
    title: "Cost-Effective Recruitment Solutions",
    rating: 5,
    content: `
      <p>I was amazed at the cost-effective solutions provided by RecruiterCRMPro. Their system has helped us cut down on recruitment costs while simultaneously increasing the quality of our hires. Their expert support team is always on hand to help.</p>
    `,
    author: 'Ethan K',
    date: 'August 21, 2023',
    datetime: '2023-08-21',
  },
  {
    id: 5,
    title: "Reliable and Responsive Support",
    rating: 4,
    content: `
      <p>What impressed me the most about RecruiterCRMPro was their responsive and reliable customer support. Whenever we have questions or need assistance, they are quick to help and provide effective solutions.</p>
    `,
    author: 'Olivia S',
    date: 'September 10, 2023',
    datetime: '2023-09-10',
  },
  {
    id: 6,
    title: "Outstanding Recruitment Partner",
    rating: 5,
    content: `
      <p>RecruiterCRMPro isn't just a platform; it's a partner in our recruitment efforts. They truly understand the needs of recruitment agencies and provide tools that are both innovative and easy to use. We couldn't be happier with the results.</p>
    `,
    author: 'Aaron M',
    date: 'October 6, 2023',
    datetime: '2023-10-06',
  },
  {
    id: 7,
    title: "Revolutionized Our Hiring Process",
    rating: 5,
    content: `
      <p>Partnering with RecruiterCRMPro has been a game-changer for our agency. Their innovative approach to recruitment has revolutionized our hiring process, making us far more effective and efficient than ever before.</p>
    `,
    author: 'Charlotte D',
    date: 'November 12, 2023',
    datetime: '2023-11-12',
  },
  {
    id: 8,
    title: "Invaluable Analytics Insights",
    rating: 4,
    content: `
      <p>The analytics insights provided by RecruiterCRMPro have been invaluable. They help us understand our recruitment patterns, improve our strategies, and achieve better outcomes with less effort.</p>
    `,
    author: 'Noah J',
    date: 'December 8, 2023',
    datetime: '2023-12-08',
  },
  {
    id: 9,
    title: "Data-Driven Recruitment",
    rating: 4,
    content: `
      <p>RecruiterCRMPro has enabled us to shift to a more data-driven recruitment process. Their platform offers powerful tools for analyzing and managing recruitment data, which has significantly boosted our performance.</p>
    `,
    author: 'Emma W',
    date: 'January 19, 2023',
    datetime: '2023-01-19',
  },
  {
    id: 10,
    title: "Transformed Our Recruitment Strategy",
    rating: 5,
    content: `
      <p>RecruiterCRMPro has transformed our recruitment strategy. Their comprehensive suite of tools and outstanding customer support have enabled us to reach and engage top talent more effectively than ever.</p>
    `,
    author: 'Liam T',
    date: 'February 24, 2023',
    datetime: '2023-02-24',
  },
]

  

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Reviews() {
  return (
    <div className="bg-white relative">
      <img
        src="./img/reviews.png"
        alt="RecruiterCRMPro - Recruiter Customer Reviews"
        className="absolute top-10 -right-10 w-48 md:w-44 lg:w-52 xl:w-96 xl:top-0 sm:top-20 sm:w-48 sm:-right-10"
        // width={2432}
        // height={1442}
      />
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <h2 className="text-3xl font-extrabold tracking-tight text-emerald-900 sm:text-5xl">We Love Happy Clients!</h2>
        <div className="mt-6 space-y-10 divide-y divide-gray-200 border-b border-t border-gray-200 pb-10">
          {reviews.map((review) => (
            <div key={review.id} className="pt-10 lg:grid lg:grid-cols-12 lg:gap-x-8">
              <div className="lg:col-span-8 lg:col-start-5 xl:col-span-9 xl:col-start-4 xl:grid xl:grid-cols-3 xl:items-start xl:gap-x-8">
                <div className="flex items-center xl:col-span-1">
                  <div className="flex items-center">
                    {[0, 1, 2, 3, 4].map((rating) => (
                      <StarIcon
                        key={rating}
                        className={classNames(
                          review.rating > rating ? 'text-yellow-400' : 'text-gray-200',
                          'h-8 w-8 flex-shrink-0'
                        )}
                        aria-hidden="true"
                      />
                    ))}
                  </div>
                  <p className="ml-3 text-sm text-gray-700">
                    {review.rating}
                    <span className="sr-only"> out of 5 stars</span>
                  </p>
                </div>

                <div className="mt-4 lg:mt-6 xl:col-span-2 xl:mt-0">
                  <h3 className="text-sm font-medium text-gray-900">{review.title}</h3>

                  <div
                    className="mt-3 space-y-6 text-sm text-gray-500"
                    dangerouslySetInnerHTML={{ __html: review.content }}
                  />
                </div>
              </div>

              <div className="mt-6 flex items-center text-sm lg:col-span-4 lg:col-start-1 lg:row-start-1 lg:mt-0 lg:flex-col lg:items-start xl:col-span-3">
                <p className="font-medium text-gray-900">{review.author}</p>
                <time
                  dateTime={review.datetime}
                  className="ml-4 border-l border-gray-200 pl-4 text-gray-500 lg:ml-0 lg:mt-2 lg:border-0 lg:pl-0"
                >
                  {review.date}
                </time>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
