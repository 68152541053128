// News.js
import React, { useEffect, useState } from 'react';
import Skeleton from './Skeleton';

const GRAPHQL_ENDPOINT = 'https://us-east-1-shared-usea1-02.cdn.hygraph.com/content/clz8r1dl7005w07w4og3y38yy/master';

const fetchBlogPosts = async () => {
  const query = `
    query GetBlogPosts {
      blogPosts(orderBy: createdAt_DESC, first: 6) {
        title
        slug
        subtitle
        content {
          html
        }
        image {
            url
        }
      }
    }
  `;

  const response = await fetch(GRAPHQL_ENDPOINT, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE3MjIzNzU3OTQsImF1ZCI6WyJodHRwczovL2FwaS11cy1lYXN0LTEtc2hhcmVkLXVzZWExLTAyLmh5Z3JhcGguY29tL3YyL2NsejhyMWRsNzAwNXcwN3c0b2czeTM4eXkvbWFzdGVyIiwibWFuYWdlbWVudC1uZXh0LmdyYXBoY21zLmNvbSJdLCJpc3MiOiJodHRwczovL21hbmFnZW1lbnQtdXMtZWFzdC0xLXNoYXJlZC11c2VhMS0wMi5oeWdyYXBoLmNvbS8iLCJzdWIiOiI3OWMwMjdhMS02YzdiLTQ4M2EtOWYzNi04YTZjNWY0ZjJiOTQiLCJqdGkiOiJjbHo4eTJzZGIwNDBiMDdsZGNudXdnd3ZqIn0.HFyvNOdOj8yZSOISCNGQ6O6htp9so8RacXDDm2c-NcfnOuEbIlT2EFt-874KPTUoTFRIIItF9gk-6nNLZjESV9El3fanZ0dPSPMGHxxRKdATycrh4dJbCkE5gfWZ0uB-8r4X-TVYairTNBTN-xVOPwPZJuEhrhO7dBz5P9z7fnry6AW4w9hNZtPD4a05TZ0YPIkp1mp1Ka0thsbyWfRILYYDJ-4Pi_DJ4pBExmdrN5yDN2wZhmG7e5FbjyrNZxFYtjMH33aN2zs-HVA4npR59cCi-gw9tHJqbJNoY76jMytDsKcw1-9ATnHkJ_aqQEy8ILD5_F6YGJ4skomyyJm0J-cMdTuTZAmAIDPpWI581dM5-fAJvHtakEbdO-UBGqsJBs55SFtPQLdqMt6aGs8qFkqRnJrs1pD8PCW2U7udd60UN6tWbe5K-qV-cAfX81Aa_oj7fODaTuMIhzuQ5C2BrMRNfq0qEE1VuXedLOQieHsQue6GRWIOq-Xt6ISDei6rtbMkadNjHF9sbP0lr9xi-wBgzk1YuC8UfQC8rcIki4yuwu8lcf1COv_3a7NjRuBx3nELLIgE--pe6ezTIlzI8u0fsVQYyTPi04i4duTzzKLp503kz-tTAB-IMnHie_EEqvvrnmgxFie4My66zN3s76DgGsqIRJvKxlB3OyOJQIM`  // If required
    },
    body: JSON.stringify({ query })
  });

  const data = await response.json();
  return data.data.blogPosts;
};

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

const News = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetchBlogPosts()
      .then((posts) => {
        setPosts(posts);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) return <Skeleton />;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
    {/* <div>
      {posts.map((post) => (
        <div key={post.slug}>
          <h2>{post.title}</h2>
          <p>{post.subtitle}</p>
          <div dangerouslySetInnerHTML={{ __html: post.content }} />
        </div>
      ))}
    </div> */}
    
    <div className="bg-white px-6 pb-20 pt-16 lg:px-8 lg:pb-28 lg:pt-24">
      <div className="relative mx-auto max-w-lg divide-y-2 divide-gray-200 lg:max-w-7xl">
        <div>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Recent Recruiter News &amp; Strategies</h2>
          <p className="mt-3 text-xl text-gray-500 sm:mt-4">
            Stay up to date with the latest news and strategies in the recruiting industry.
          </p>
        </div>
        <div className="mt-12 grid gap-16 pt-12 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-12">
          {posts.map((post) => (
            <div key={post.title}>
              <div>
                <a href={`/news/${post.slug}`} className="inline-block">
                    
                    {post.image !== null && 
                    <div className="rounded-lg bg-emerald-200 p-2 shadow-md">
                        <img src={post.image.url} alt="" className="w-full h-auto rounded-lg" />
                    </div>
                    }
                </a>
              </div>
              <a href={`/news/${post.slug}`} className="mt-4 block">
                <p className="text-xl font-semibold text-gray-900">{post.title}</p>
                <p className="mt-3 text-base text-gray-500">{post.subtitle}</p>
              </a>
              {/* <div className="mt-6 flex items-center">
                <div className="flex-shrink-0">
                  <a href={post.author.href}>
                    <span className="sr-only">RecruiterCRMPro</span>
                    <img alt="" src={post.author.imageUrl} className="h-10 w-10 rounded-full" />
                  </a>
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-gray-900">
                    <a href="/">Recruiting Software</a>
                  </p>
                  <div className="flex space-x-1 text-sm text-gray-500">
                    <time dateTime={post.datetime}>{post.date}</time>
                    <span aria-hidden="true">&middot;</span>
                    <span>{post.readingTime} read</span>
                  </div>
                </div>
              </div> */}
            </div>
          ))}
        </div>
      </div>
    </div>
    </>
  );
};

export default News;
