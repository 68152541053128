import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import About from './pages/About';
import Features from './pages/Features';
import Pricing from './pages/Pricing';
import Resources from './pages/Resources';
import LatestNews from './pages/LatestNews.js';
import Article from './pages/Article.js';

import './App.css';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        {/* <Route path="/features" element={<Features />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/resources" element={<Resources />} /> */}
        <Route path="/news" element={<LatestNews />} />
        <Route path="/news/:slug" element={<Article />} />
        {/* Add more routes here */}
      </Routes>
    </Router>
  );
}

export default App;
